.App {
  text-align: center;
}

body {
  background: url("https://images.pexels.com/photos/633409/pexels-photo-633409.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
}
#box {
  position: relative;
  background: rgba(16, 15, 15, 0.2);
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
}
.text {
  color: #9a893b;
  font-size: normal;
  letter-spacing: normal;

}

.title {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  color: #FFF;
  text-align: center;
  
  font-weight: 300;
  font-size: 30px;
  letter-spacing: 10px;
  margin-top: -60px;
  padding-left: 10px;
  animation: fadeIn 2s;
}
.bio {
  position: fixed;

  left: 0;
  right: 0;
  text-align: center;


  margin-top: -60px;
  padding-left: 10px;
  animation: fadeIn 5s;
}

.logo {
  margin-top: 7.5rem;
  width: 200px;
  padding: .5rem;
}
.logo2 {
  margin-top: 7.5rem;
  width: 150px;
  padding: .5rem;
}
.btn-logo {
  width: 100px
}
bio p {
  text-align: center;
  font-family: "lato", sans-serif;
  font-size: 35px;
  font-weight: 300;
  
}
.avatar {
  margin-top: 60px;
  margin-left: 15%;
  border-radius: .25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 2);
  color: #d4cece;
  flex: 0 0 100%;
  flex-direction: row;
}
.flex-container {
  display: flex;
}
/* .span {
  background: -webkit-linear-gradient(white, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
.text-container {
  margin-left: .5rem;
  margin-right: .5rem;
}

.card {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  padding: 1rem;
  text-align: left;
  background: white;
  border-radius: .25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 2);
}

.project-img {
  width: 100%;
  cursor: pointer;
}

li {
  text-align: left;
}

hr {
  color: 'white'
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  
}
h1.header {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #FFF;
}

.project-tile {
  font-family: 'Times New Roman', Times, serif;
  color: #FFF;
}

.btn-dark.contact-btn {
  margin: 7%;
}

.btn-container {
  display: flex;
  justify-content: center;
}